import { TeammatesServicesImpl } from './teammates.service.implementation'
import { TeammatesServicesMockImpl } from './teammates.service.mock.implementation'
import { TeammatesService } from './teammates.service.type'

export const TeammatesServiceFactory = (token: string, agentId: string): TeammatesService => {
  if (process.env.REACT_APP_ENV === 'development') {
    return new TeammatesServicesMockImpl()
  }

  return new TeammatesServicesImpl(agentId, token)
}
