/* eslint-disable camelcase */
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { AUTH_SERVICE_URL } from '../../avenue-8-platform-shared'
import { getAuthSBLInstance } from '../auth'
import { AuthCredentials } from '../auth/Auth.interface'
import { UserDataDto } from './domain/user-data.dto'

export const UserDataContext = createContext<UserDataDto | null>(null)

export const useUserDataContext = (): UserDataDto => useContext(UserDataContext)

export const UserDataProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [userProfile, setUserProfile] = useState<UserDataDto>(null)
  useEffect(() => {
    const authSBL = getAuthSBLInstance()
    const handler = async (credentials: AuthCredentials) => {
      if (!credentials) {
        return authSBL.requestAuthentication(true)
      }
      const { token, agentId } = credentials
      const headers = new Headers([['Authorization', `Bearer ${token}`]])
      const params = new URLSearchParams({ agentId, profile: 'true' })
      fetch(`${AUTH_SERVICE_URL}/agent?${params}`, { headers, credentials: 'include' })
        .then((response) => {
          if (!response.ok) {
            setUserProfile(null)
            throw new Error('failed to get user profile')
          }
          return response.json()
        })
        .then((data: UserDataDto) => {
          setUserProfile(data)
        })
        .catch((err) => {
          console.error('Failed to get user profile:', err.message, err)
          setUserProfile(null)
        })
    }
    authSBL.addOnAuthChangeListener(handler)
    authSBL.getCredentials().then(handler)
    return () => {
      authSBL.removeOnAuthChangeListener(handler)
    }
  }, [])
  return <UserDataContext.Provider value={userProfile}>{children}</UserDataContext.Provider>
}
