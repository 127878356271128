import { PROPERTY_SEARCH_API_URL } from '../../avenue-8-platform-shared'
import { GetAgentsByFiltersDto, GetAgentsByFiltersParams } from '../shared/domain/property-search.dto'
import { ResponseType } from '../shared/domain/response.type'
import { PropertySearchService } from './property-search.service.type'

export class PropertySearchServicesImpl implements PropertySearchService {
  agentId: string
  token: string

  constructor(agentId: string, token: string) {
    this.agentId = agentId
    this.token = token
  }

  async getAgentsByFilters(filters: GetAgentsByFiltersParams): Promise<ResponseType<GetAgentsByFiltersDto[]>> {
    try {
      const headers = new Headers()
      headers.set('Content-Type', 'application/json')
      headers.set('Authorization', `Bearer ${this.token}`)

      const response = await fetch(`${PROPERTY_SEARCH_API_URL}/api/v1/agents`, {
        method: 'POST',
        headers,
        body: JSON.stringify(filters),
      })

      if (response.ok) {
        const data = await response.json()
        return { success: true, data }
      }
      return { success: false }
    } catch (err) {
      console.error('Error fetching agents by filters', err)
      return { success: false }
    }
  }
}
