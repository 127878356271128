import { getAuthSBLInstance } from '../auth'

const handler = async (credentials) => {
  if (window && window.heap) {
    const agentId = credentials ? credentials.agentId : null
    const jwt = credentials ? credentials.token : null
    if (agentId && agentId.toString().length) {
      window.heap.identify(agentId.toString())
      try {
        const [, serializedPayload] = jwt.split('.')
        const { sub: user, username: email } = JSON.parse(serializedPayload)
        window.heap.addUserProperties({ email, user })
      } catch (err) {
        console.error('Could not read the username in JWT')
      }
    } else {
      window.heap.resetIdentity()
    }
  } else {
    // eslint-disable-next-line no-console
    console.debug('Heap API was not found')
  }
}
getAuthSBLInstance().addOnAuthChangeListener(handler)
getAuthSBLInstance().getCredentials().then(handler)
