import { WebviewBridgeHandlerName } from './webview-bridge-handlers.type'
import { WebviewBridge } from './webview-bridge.type'

const bridges = {
  ios: () => Boolean(window.webkit && window.webkit.messageHandlers),
  reactNative: () => Boolean(window.ReactNativeWebView && window.ReactNativeWebView.postMessage),
}

const isInsideWebview = () => {
  return window && (bridges.ios() || bridges.reactNative())
}

export const WebviewBridgeFactory = (): WebviewBridge | null => {
  if (!isInsideWebview()) {
    return null
  }

  return {
    postMessage(name: WebviewBridgeHandlerName, data?: Record<string, any>) {
      if (bridges.ios()) {
        window.webkit.messageHandlers[name]?.postMessage(JSON.stringify(data ?? {}))
      }
      if (bridges.reactNative()) {
        window.ReactNativeWebView.postMessage(JSON.stringify(data ? { ...data, name } : { name }))
      }
      window.postMessage(name)
    },
  }
}
