import { createContext, PropsWithChildren, useContext, useEffect } from 'react'
import { getAuthSBLInstance } from '../auth'
import { FeatureFlagsServiceInterface } from './feature-flags-service.interface'
import { FlagsmithFactory } from './flagsmith.service'
import { AuthCredentials } from '../auth/Auth.interface'

export const FeatureFlagsContext = createContext<FeatureFlagsServiceInterface>({
  async hasFeature(_flag): Promise<boolean> {
    return false
  },
  addFeaturesListener(_listener) {
    //
  },
  removeFeaturesListener(_listener) {
    //
  },
  async identify(_agentId, _traits?): Promise<void> {
    //
  },
  async getFeatureData(_flag: string) {
    return null
  },
} as FeatureFlagsServiceInterface)

export const useFeatureFlags = (): FeatureFlagsServiceInterface => useContext(FeatureFlagsContext)

export const FeatureFlagsProvider = ({
  children,
  value,
}: PropsWithChildren<{ value?: FeatureFlagsServiceInterface }>): JSX.Element => {
  const service = value || FlagsmithFactory()

  useEffect(() => {
    const handler = async (credentials: AuthCredentials) => {
      if (credentials?.agentId) {
        // signs agent in
        service.identify(credentials.agentId).catch((err) => {
          console.error('[FeatureFlagsProvider] Failed to identify agent:', err)
        })
      } else {
        // signs agent out
        service.identify(null).catch((err) => {
          console.error('[FeatureFlagsProvider] Failed to sign agent out:', err)
        })
      }
    }

    if (!service) return

    getAuthSBLInstance().addOnAuthChangeListener(handler)
    getAuthSBLInstance().getCredentials().then(handler)
  }, [service])
  return <FeatureFlagsContext.Provider value={service}>{children}</FeatureFlagsContext.Provider>
}
