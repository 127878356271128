import { AgentListingDto } from '../shared/domain/agent-listing.dto'
import { ResponseType } from '../shared/domain/response.type'
import { ListingsService } from './listings.service.type'
import { PropertySearchV2Provider } from './property-search-v2/property-search-v2-provider'

export class ListingServicesImpl implements ListingsService {
  agentId: string
  token: string
  propertySearchInstance: PropertySearchV2Provider

  constructor(agentId: string, token: string) {
    this.propertySearchInstance = new PropertySearchV2Provider()

    this.agentId = agentId
    this.token = token
  }

  async getAgentRecentAddress(dre: string, limit?: number): Promise<ResponseType<AgentListingDto[]>> {
    try {
      const data = await this.propertySearchInstance.findAgentListings(dre, this.token)
      return { success: true, data }
    } catch (err) {
      return { success: false }
    }
  }

  async findByText(text: string): Promise<ResponseType<AgentListingDto[]>> {
    try {
      const data = await this.propertySearchInstance.findByTextSearch(text)
      return { success: true, data }
    } catch (err) {
      return { success: false }
    }
  }
}
