import { IAuthSharedBusinessLogic } from './Auth.interface'
import { AuthSBLFactoryOptions } from './Auth.factory'
import { getAuthSBLInstance as getInstance } from './Auth.singleton'

declare global {
  export interface Window {
    av8: {
      auth: Av8Auth
    }
  }
}

export type Av8Auth = AuthSBLFactoryOptions & {
  getAuthSBLInstance: () => IAuthSharedBusinessLogic
  isInstantiated: () => boolean
  setToken?: (token: string, agentId: string) => void
}

const previousAv8Object = window.av8 || { auth: {} }

const auth: Av8Auth = { getAuthSBLInstance: getInstance, isInstantiated: () => true }

window.av8 = {
  ...previousAv8Object,
  auth: { ...(previousAv8Object.auth || {}), ...auth },
}

export { getAuthSBLInstance, isInstantiated as isAuthSBLAvailable } from './Auth.singleton'
export { useAuthContext, AuthProvider } from './Auth.context'
export { AuthedFetch } from './authed-fetch'
