import { MouseEvent, useEffect, useState } from 'react'
import { useAuthContext } from '../../auth'
import { useFeatureFlags } from '../../feature-flags'
import { WebviewBridgeHandlerName } from '../../webview-bridge/webview-bridge-handlers.type'
import { useWebviewBridgeContext } from '../../webview-bridge/webview-bridge.context'
import { OptionDto } from '../../shared/domain/option.dto'
import { UserMenuComponentType } from '../domain/user-menu-component.type'
import { useUserDataContext } from '../user-data.context'

const signOutOption: OptionDto = { label: 'Sign Out', value: '#' }

export const HistoryUserMenu = ({
  UserMenuComponent,
  embed = false,
  // TODO: Remove the default value once 'pam2-user-menu-options' is passed as props
  featureString = 'pam2-user-menu-options',
  customSignOut,
}: {
  UserMenuComponent: UserMenuComponentType
  embed?: boolean
  featureString?: string
  customSignOut?: OptionDto
}): JSX.Element => {
  const { authSBL } = useAuthContext()
  const { postMessage } = useWebviewBridgeContext()
  const userData = useUserDataContext()
  const { getFeatureData } = useFeatureFlags()
  const [options, setOptions] = useState<OptionDto[]>([])

  const menuOptions = [...options, customSignOut || signOutOption]

  const handleClick = (setAnchorEl: (value: HTMLElement | null) => void, currentTarget: HTMLElement) => {
    if (embed && postMessage) {
      postMessage(WebviewBridgeHandlerName.toggleSidebar, { open: true })
    } else {
      setAnchorEl(currentTarget)
    }
  }

  const handleClose = (setAnchorEl: (value: HTMLElement | null) => void) => {
    setAnchorEl(null)
    if (embed && postMessage) {
      postMessage(WebviewBridgeHandlerName.toggleSidebar, { open: false })
    }
  }

  const handleMenuItemClick = (option: OptionDto, event: MouseEvent<HTMLAnchorElement>) => {
    if (option.value === signOutOption.value) {
      event.nativeEvent.stopPropagation()
      event.nativeEvent.preventDefault()
      authSBL.signOut()
    }
  }

  const filterOptionsByEmbed = (data) => {
    return (data as OptionDto<string>[]).filter((option) => {
      if (option.isMobile !== undefined && option.isMobile !== null) {
        return option.isMobile === true
      }
      return true
    })
  }

  useEffect(() => {
    getFeatureData(featureString).then((data) => {
      if (!data) {
        if (options.length) {
          setOptions([])
        }
        return
      }
      if (!Array.isArray(data)) {
        throw new Error('invalid user menu options feature flag')
      }

      if (embed) {
        const filteredOptions = filterOptionsByEmbed(data)
        setOptions(filteredOptions)
      } else {
        setOptions(data as OptionDto<string>[])
      }
    })
  }, [embed, featureString, options?.length, getFeatureData])

  return (
    <UserMenuComponent
      userName={userData?.profile?.name}
      userAvatarUrl={userData?.profile?.photo}
      options={menuOptions}
      onClick={handleClick}
      onClose={handleClose}
      onMenuItemClick={handleMenuItemClick}
    />
  )
}
