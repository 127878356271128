import {
  AuthCredentials,
  AuthSharedBusinessLogicConstructorOptions,
  IAuthSharedBusinessLogic,
  OnAuthChangeHandler,
} from './Auth.interface'

export class EmbedAuthSbl implements IAuthSharedBusinessLogic {
  private credentials: AuthCredentials
  private handlers: Set<OnAuthChangeHandler>

  constructor(options: AuthSharedBusinessLogicConstructorOptions) {
    this.credentials = null
    this.handlers = new Set([])

    window.av8 = {
      ...(window?.av8 ?? {}),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      auth: {
        ...(window?.av8?.auth ?? {}),
        setToken: this.setToken,
      },
    }

    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.has('token') && queryParams.has('agentId')) {
      const token = queryParams.get('token')
      const agentId = queryParams.get('agentId')
      this.setToken(token, agentId)
      window.sessionStorage.setItem('av8_mbd_auth_credentials', JSON.stringify({ token, agentId }))
    } else {
      try {
        const serializedCredentials = window.sessionStorage.getItem('av8_mbd_auth_credentials')
        const credentials = JSON.parse(serializedCredentials)
        this.setToken(credentials.token, credentials.agentId)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.debug('Failed to parse stored credentials', error)
      }
    }
  }

  private setToken(token: string, agentId: string): void {
    this.credentials = { agentId, token, refresh: null }
    window.sessionStorage.setItem('av8_mbd_auth_credentials', JSON.stringify({ token, agentId }))
    Array.from(this.handlers.values()).forEach((handler: OnAuthChangeHandler) => {
      handler(this.credentials).catch((err) => {
        console.error('[Auth SBL] Failed to run handler:', err)
      })
    })
  }

  addOnAuthChangeListener(handler: OnAuthChangeHandler): void {
    this.handlers.add(handler)
  }

  getCredentials(): Promise<AuthCredentials | null> {
    return Promise.resolve(this.credentials)
  }

  isAuthenticated(): Promise<boolean> {
    return Promise.resolve(!!this.credentials?.token)
  }

  removeOnAuthChangeListener(handler: OnAuthChangeHandler): void {
    this.handlers.delete(handler)
  }

  requestAuthentication(redirect: boolean): Promise<void> {
    return Promise.resolve(undefined)
  }

  signOut(redirectTo?: string): Promise<void> {
    if (redirectTo) {
      window.location.href = redirectTo
    }
    return Promise.resolve(undefined)
  }
}
