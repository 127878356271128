import flagsmith from 'flagsmith'
import { getAuthSBLInstance } from '../auth'
import { FeatureFlagChangeListener } from './feature-flag-change-listener'
import { FeatureFlagInterface } from './feature-flag.interface'
import { FeatureFlagsServiceInterface } from './feature-flags-service.interface'
import { AuthCredentials } from '../auth/Auth.interface'

let cold = true
let listeners: FeatureFlagChangeListener[] = []

flagsmith
  .init({
    cacheFlags: true,
    enableAnalytics: true,
    enableLogs: process.env.DEBUG_FLAGSMITH === 'true',
    environmentID: process.env.FLAGSMITH_ENV_ID,
    onChange: (previousFlags, retrieveInfo) => {
      const flags = flagsmith.getAllFlags()
      const flagList: FeatureFlagInterface[] = Object.entries(flags).map(([featureName, featureData]) => {
        return {
          active: featureData.enabled,
          name: featureName,
          data: featureData.value,
        }
      })
      const { flagsChanged, traitsChanged } = retrieveInfo
      if (flagsChanged || traitsChanged || cold) {
        cold = false
        listeners.forEach((listener) => {
          listener(flagList).catch((err) => {
            console.error('Failed to run feature flag listener:', err)
          })
        })
      }
    },
    onError: (error) => {
      console.error('[Flagsmith]', error.message, error)
    },
  })
  .then(() => {
    return flagsmith.getFlags()
  })
  .catch((err) => {
    console.error('Failed to init flagsmith:', err)
  })

const handler = async (credentials: AuthCredentials) => {
  if (credentials) {
    const id =
      process.env.NODE_ENV !== 'production'
        ? [process.env.NODE_ENV, credentials.agentId].join('-')
        : credentials.agentId
    return flagsmith.identify(id, { roles: 'user' }).then(() => flagsmith.getFlags())
  }
}

getAuthSBLInstance().addOnAuthChangeListener(handler)
getAuthSBLInstance().getCredentials().then(handler)

export function FlagsmithFactory(): FeatureFlagsServiceInterface {
  return {
    async identify(agentId, traits) {
      if (agentId === null) {
        await flagsmith.logout()
      } else {
        await flagsmith.identify(agentId, traits)
      }
      return flagsmith.getFlags()
    },
    addFeaturesListener(listener: FeatureFlagChangeListener) {
      listeners.push(listener)
    },
    removeFeaturesListener(listener: FeatureFlagChangeListener) {
      const idx = listeners.indexOf(listener)
      if (idx > -1) {
        listeners = listeners.filter((registeredListener) => registeredListener !== listener)
      }
    },
    async getFeatureData(flag: string) {
      await flagsmith.getFlags()
      const value = flagsmith.getValue(flag)
      if (typeof value !== 'string') {
        return value ?? null
      }
      try {
        return JSON.parse(value)
      } catch (_err) {
        return value ?? null
      }
    },
    async hasFeature(flag: string) {
      await flagsmith.getFlags()
      return flagsmith.hasFeature(flag)
    },
    async setTrait(key: string, value: string | number | boolean) {
      await flagsmith.setTrait(key, value)
    },
  }
}

export const FlagsmithService = FlagsmithFactory()
