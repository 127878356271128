import { createContext, PropsWithChildren, useState, useEffect, useContext } from 'react'
import { useAuthContext } from '../auth'
import { PropertySearchServiceFactory } from './property-search.service.factory'
import { PropertySearchService } from './property-search.service.type'

export type PropertySearchContextValue = {
  propertySearchService: PropertySearchService
}

export const usePropertySearch: () => PropertySearchContextValue = () => useContext(PropertySearchContext)

export const PropertySearchContext = createContext<PropertySearchContextValue>({
  propertySearchService: null,
})

export const PropertySearchContextProvider = (props: PropsWithChildren<any>): JSX.Element => {
  const { agentId, token } = useAuthContext()
  const [propertySearchService, setPropertySearchService] = useState<PropertySearchService>(null)

  useEffect(() => {
    if (agentId && token) {
      const propertySearchService = PropertySearchServiceFactory(token, agentId)
      setPropertySearchService(propertySearchService)
    }
  }, [agentId, token])

  return (
    <PropertySearchContext.Provider
      value={{
        propertySearchService,
      }}
    >
      {props.children}
    </PropertySearchContext.Provider>
  )
}
