import { IAuthSharedBusinessLogic, IAuthSharedBusinessLogicConstructor } from './Auth.interface'

export type AuthSBLFactoryOptions = {
  domain?: string
  updateInterval?: number
  tokenRefreshDelta?: number
}

export function createAuthSBL(
  Constructor: IAuthSharedBusinessLogicConstructor,
  authUrl: string,
  options: AuthSBLFactoryOptions
): IAuthSharedBusinessLogic {
  return new Constructor({ authUrl, ...options })
}
