import { datadogLogs as datadogLogsInstance, LogsInitConfiguration } from '@datadog/browser-logs'
import { getAuthSBLInstance } from '../auth'
import { AuthCredentials } from '../auth/Auth.interface'

type DatadogLogsInstance = typeof datadogLogs

export const datadogLogs = datadogLogsInstance

const handler =
  (options: Partial<LogsInitConfiguration> = {}) =>
  async (credentials: AuthCredentials) => {
    const initConfig = datadogLogs.getInitConfiguration()

    if (!initConfig) {
      datadogLogs.init({
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        site: process.env.REACT_APP_DATADOG_SITE ?? 'us5.datadoghq.com',
        service: process.env.REACT_APP_SERVICE_NAME,
        version: process.env.REACT_APP_SERVICE_VERSION,
        env: process.env.NODE_ENV,
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        ...options,
      })
    }

    const jwt = credentials ? credentials.token : null

    if (!jwt) return

    try {
      const payload = JSON.parse(atob(jwt.split('.')[1]))
      const { sub: id, username: email } = payload
      datadogLogs.setUser({ email, id })
    } catch (err) {
      console.error('Could not read the username in JWT')
      datadogLogs.clearUser()
    }
  }

export function initDatadogLogs(options?: Partial<LogsInitConfiguration>): DatadogLogsInstance {
  if (!process.env.REACT_APP_DATADOG_APPLICATION_ID || !process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
    console.warn('Datadog Logs is not configured.')
    return
  }
  getAuthSBLInstance().addOnAuthChangeListener(handler(options))
  getAuthSBLInstance().getCredentials().then(handler(options))
  return datadogLogs
}
