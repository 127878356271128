function gtm() {
  if (!document) {
    console.info('No document found')
    return
  }

  console.info('Found document')

  if (shouldPreventGTMLoad()) {
    console.info('Heap will not be initialized by GTM')
    return
  }

  const GTMScript = document.createElement('script')

  GTMScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.GTM_ID}');`

  document.head.appendChild(GTMScript)
}

function shouldPreventGTMLoad() {
  const closingHandler = () => location.pathname.startsWith('/closing')
  const handlers: Array<() => boolean> = [closingHandler]
  return handlers.every((handler) => handler())
}

gtm()
