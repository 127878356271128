import { createContext, ReactNode, useContext, useState } from 'react'
import { WebviewBridgeFactory } from './webview-bridge.factory'
import { WebviewBridge } from './webview-bridge.type'

export type WebviewBridgeContextValue = WebviewBridge | Record<string, never>

export const WebviewBridgeContext = createContext<WebviewBridgeContextValue>({})

export const WebviewBridgeProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [webviewBridge] = useState<WebviewBridgeContextValue>(WebviewBridgeFactory() ?? {})
  return <WebviewBridgeContext.Provider value={webviewBridge}>{children}</WebviewBridgeContext.Provider>
}

export const useWebviewBridgeContext = (context = WebviewBridgeContext) => useContext(context)
