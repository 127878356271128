import {
  ExportShareModalProps as PresentationalComponentProps,
  Handlers,
  Triggers,
  DefaultFile,
  ProjectImage,
  SocialShareOption,
  DownloadFormat,
} from '@avenue-8/platform-style-util-frontend'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { MediaType } from '../../design-huddle/media-type.type'

enum ContentContextEnum {
  'LISTING_LOCATION' = 'LISTING_LOCATION',
  'LISTING_INFORMATION_FROM_PROPERTY_SEARCH' = 'LISTING_INFORMATION_FROM_PROPERTY_SEARCH',
}

interface PropertyInfoDto {
  mlsId?: string | null
  mlsSource?: string | null
  addressLine1?: string
  addressLine2?: string | null
  area?: string | null
  numBathrooms?: number | null
  numBedrooms?: number | null
  lat?: number | null
  lng?: number | null
  listingStatus?: string | null
  city?: string
  description?: string | null
  parkingGarageSpaces?: number | null
  listDate?: Date | null
  listPrice?: number | null
  livingSpace?: number | null
  lotSize?: number | null
  neighborhood?: string | null
  propertyType?: string | null
  state?: string
  yearBuilt?: number | null
  zipCode?: string
  soldPrice?: number | null
  soldDate?: Date | null
  county?: string | null
}

export type ExportShareModalProps = {
  PresentionalComponent: (props: PresentationalComponentProps) => JSX.Element
  pagePreviews: ProjectImage[]
  handlers: Handlers[]
  status: 'IDLE' | 'LOADING' | 'ERROR'
  captionStatus?: 'IDLE' | 'LOADING' | 'ERROR'
  open: boolean
  onClose: () => void
  title: string
  subtitle?: string
  /**
   * Project/Template media type to define export options, will default to
   * digital if undefined
   */
  mediaType?: MediaType
  /**
   * Manually overwrite list of export options generated based on mediaType
   * @see ExportShareModalProps.mediaType
   */
  exportFileTypes?: DefaultFile[]
  onDownloadClick?: (fileName: string, fileType: DownloadFormat) => void
  onEmailShareClick?: (emailAddress: string) => void
  onSocialShareClick?: (network: SocialShareOption, mediaArray: string[]) => void
  onCustomizeClick?: () => void
  socialOptions?: SocialShareOption[]
  socialMediaShareMessage?: string
  defaultValues?: {
    fileName?: string
    fileType?: DownloadFormat
    caption?: string
    targetAudience?: string
    targetAudienceTopic?: string
    captionContext?: string
    tonality?: string
    hashtagsOption?: 'INCLUDE' | 'ONLY' | 'DO_NOT_INCLUDE'
  }
  promoteCTA?: JSX.Element
  onGenerateCaptionClick?: (params: {
    captionContext?: string
    address?: string
    contentContexts?: ContentContextEnum[]
    maxNumberOfWords?: number
    propertyInfo?: PropertyInfoDto
    numberOfOutputs?: number
    tonality?: string
    contentType?: 'CAPTION' | 'SOCIAL_MEDIA_POST'
    targetAudience?: string
    targetAudienceTopic?: string
    hashtagsOption?: 'INCLUDE' | 'ONLY' | 'DO_NOT_INCLUDE'
  }) => void
  onChangeCaption?: (caption: string) => void
}

const generateExportFileTypes = (formats: DownloadFormat[]) =>
  formats.map((value: DownloadFormat): DefaultFile => ({ value, label: value.toLocaleUpperCase() }))
const videoExportFileTypes = generateExportFileTypes(['mp4'])
const digitalExportFileTypes = generateExportFileTypes(['png', 'pdf'])
const printExportFileTypes = generateExportFileTypes(['pdf', 'png'])

const targetAudiencesOptions = [
  {
    label: 'Unspecified',
    value: 'Unspecified',
    options: [],
  },
  {
    label: 'Sellers',
    value: 'Sellers',
    options: [
      'Tips for Preparing your Home for Listing',
      'Home Selling Techniques',
      'Maximizing your Home’s Sale Price',
      'Updating and Renovating for Increased Value',
    ],
  },
  {
    label: 'First-Time Home Buyers',
    value: 'First-Time Home Buyers',
    options: ['Home Buying Dos & Don’ts', 'Home Buyer Resources', 'Renting vs. Buying Comparisons'],
  },
  {
    label: 'Rentals',
    value: 'Rentals',
    options: [
      'Attracting Long-Term and High-Quality Tenants',
      'Turning Your Home into a Rental Income Stream',
      'Advantages of Renting',
      'Exploring Different Rental Types',
    ],
  },
  {
    label: 'Families',
    value: 'Families',
    options: [
      'Tips & Tricks to Moving with Kids',
      'Buying your Ideal Family Home',
      'Family-Oriented Home Features',
      'Upgrading with a Growing Family',
    ],
  },
  // {
  //   label: 'Real Estate Investors',
  //   value: 'Real Estate Investors',
  //   options: [
  //     'Strategic Investment Strategies',
  //     'Multi-Family Investment Properties',
  //     'Exit Strategies: Knowing When to Sell',
  //     'Financing Real Estate Ventures',
  //   ],
  // },
]

const tonalitiesOptions = [
  { label: 'Professional', value: 'authoritative, formal' },
  { label: 'Luxurious', value: 'commanding, refined, articulate, eloquent, formal' },
  { label: 'Bold', value: 'engaging, informative' },
  { label: 'Friendly', value: 'enthusiastic, warm' },
  { label: 'Relaxed', value: 'informal, casual, conversational' },
]

export const ExportShareModal = ({
  PresentionalComponent,
  pagePreviews,
  handlers,
  status,
  captionStatus,
  open,
  onClose,
  title,
  subtitle,
  mediaType = 'digital',
  exportFileTypes: exportFileTypeOptions,
  onDownloadClick,
  onEmailShareClick,
  onCustomizeClick,
  socialOptions,
  socialMediaShareMessage,
  onSocialShareClick,
  defaultValues,
  promoteCTA,
  onGenerateCaptionClick,
  onChangeCaption,
}: ExportShareModalProps): JSX.Element => {
  const [fileName, setFileName] = useState<string>(defaultValues?.fileName ?? '')
  const [fileType, setFileType] = useState<DownloadFormat | null>(defaultValues?.fileType ?? null)
  const [emailAddress, setEmailAddress] = useState<string>('')
  const [targetAudience, setTargetAudience] = useState<string>('Unspecified')
  const [targetAudienceTopic, setTargetAudienceTopic] = useState<string>('')
  const [captionContext, setCaptionContext] = useState('')
  const [tonality, setTonality] = useState('')
  const [hashtagsOption, setHashtagsOption] = useState<'INCLUDE' | 'ONLY' | 'DO_NOT_INCLUDE'>('INCLUDE')

  const exportFileTypes = useMemo((): DefaultFile[] => {
    // TODO: remove later as this is temporary just to not break previous usages
    if (exportFileTypeOptions) {
      return exportFileTypeOptions
    }
    switch (mediaType) {
      case 'video':
        return videoExportFileTypes
      case 'print':
        return printExportFileTypes
      default:
        return digitalExportFileTypes
    }
  }, [exportFileTypeOptions, mediaType])

  useEffect(() => {
    setFileType(exportFileTypes?.[0]?.value ?? null)
  }, [exportFileTypes])

  const handleFileNameChange = (event) => {
    const {
      target: { value },
    } = event
    setFileName(value)
  }

  const handleFileTypeChange = (event) => {
    const {
      target: { value },
    } = event
    setFileType(value)
  }

  const handleEmailAddressChange = (event) => {
    setEmailAddress(event?.target?.value ?? '')
  }

  const handleEmailAddressSubmit = () => {
    onEmailShareClick?.(emailAddress)
  }

  const handleDownloadClick = () => {
    onDownloadClick?.(fileName, fileType)
  }

  const handleSocialShareClick = (network: SocialShareOption) => () => {
    const previewUrls = pagePreviews.map((page) => page.url) || []
    onSocialShareClick?.(network, previewUrls)
  }

  const handleCaptionClick = () => {
    navigator.clipboard.writeText(defaultValues?.caption ?? '')
  }

  const handleCaptionChange = (e) => {
    onChangeCaption(e.target.value)
  }

  const handleChangeTargetAudience = (e) => {
    setTargetAudience(e.target.value)
    setTargetAudienceTopic('')
  }

  const handleChangeTargetAudienceTopic = (e) => {
    setTargetAudienceTopic(e.target.value)
  }

  const handleChangeTonality = (e) => {
    setTonality(e.target.value)
  }
  const handleChangeHashtagsOption = (e) => {
    setHashtagsOption(e.target.checked ? 'INCLUDE' : 'DO_NOT_INCLUDE')
  }

  const handleChangeCaptionContext = (e) => {
    const value = e.target.value
    if (String(value).length > 50) return
    setCaptionContext(e.target.value)
  }

  const handleGenerateCaptionClick = useCallback(() => {
    onGenerateCaptionClick({
      captionContext,
      targetAudience,
      targetAudienceTopic,
      tonality,
      hashtagsOption,
    })
  }, [onGenerateCaptionClick, captionContext, targetAudience, targetAudienceTopic, tonality, hashtagsOption])

  const triggers: Triggers = {
    preview: handlers.includes('preview'),
    download: handlers.includes('download')
      ? {
          fields: {
            fileName: {
              onChange: handleFileNameChange,
            },
            fileType: {
              onChange: handleFileTypeChange,
            },
          },
          onClick: handleDownloadClick,
        }
      : false,
    emailShare: handlers.includes('emailShare')
      ? {
          onClick: handleEmailAddressSubmit,
          onChange: handleEmailAddressChange,
        }
      : false,
    socialShare: handlers.includes('socialShare')
      ? {
          onClick: handleSocialShareClick,
        }
      : false,
    caption: handlers.includes('caption')
      ? {
          onClick: handleCaptionClick,
          onChange: handleCaptionChange,
        }
      : false,
    generateCaptionWithAI: handlers.includes('caption')
      ? {
          onClick: handleGenerateCaptionClick,
          fields: {
            targetAudience: {
              onChange: handleChangeTargetAudience,
            },
            targetAudienceTopic: {
              onChange: handleChangeTargetAudienceTopic,
            },
            captionContext: {
              onChange: handleChangeCaptionContext,
            },
            tonality: {
              onChange: handleChangeTonality,
            },
            hashtagsOption: {
              onChange: handleChangeHashtagsOption,
            },
          },
        }
      : false,
    customize: handlers.includes('customize')
      ? {
          onClick: onCustomizeClick,
        }
      : false,
  }

  return (
    <PresentionalComponent
      open={open}
      onClose={onClose}
      status={status}
      captionStatus={captionStatus}
      title={title}
      subtitle={subtitle}
      previews={pagePreviews}
      triggers={triggers}
      values={{
        fileName,
        fileType,
        caption: defaultValues?.caption || '',
        targetAudience,
        targetAudienceTopic,
        captionContext,
        tonality,
        hashtagsOption,
      }}
      exportFileTypes={exportFileTypes}
      socialOptions={socialOptions}
      socialMediaShareMessage={socialMediaShareMessage}
      promoteCTA={promoteCTA}
      targetAudiencesOptions={targetAudiencesOptions}
      tonalitiesOptions={tonalitiesOptions}
    />
  )
}
