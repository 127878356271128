import JSZip from 'jszip'
import { triggerDownload } from './trigger-download'

const cleanFileName = (filename: string): string => filename.replace(/\./g, '')

export async function ZipDownload(
  filename: string,
  files: { url: string; id: string; filename?: string }[],
  format: string
): Promise<void> {
  const zip = new JSZip()

  for (let idx = 0; idx < files.length; idx++) {
    const file = files[idx]
    const response = await fetch(file.url)
    const fileData = await response.blob()
    const safeFilename = cleanFileName(file.filename || `${filename}_${idx + 1}`)
    const fileName = `${safeFilename}.${format}`
    zip.file(fileName, fileData, { base64: true })
  }

  const zipFile = await zip.generateAsync({ type: 'blob' })
  const cleanFilename = cleanFileName(filename)
  return triggerDownload(zipFile, `${cleanFilename}.zip`)
}
