import { IAuthSharedBusinessLogic } from './Auth.interface'
import { DesktopAuthSbl } from './DesktopAuth.sbl'
import { createAuthSBL } from './Auth.factory'
import { EmbedAuthSbl } from './EmbedAuth.sbl'

const queryParams = new URLSearchParams(window.location.search)

const isEmbed: boolean =
  !!window?.webkit?.messageHandlers?.exit?.postMessage ||
  (queryParams.has('mbd') && queryParams.get('mbd') === 'true') ||
  !!sessionStorage.getItem('av8_mbd_auth_credentials')

const authUrl: string = process.env.AUTH_SERVICE_URL || 'https://auth.production.svc.avenue8.com'
const domain: string = process.env.AUTH_DOMAIN || 'avenue8.com'
const { updateInterval, tokenRefreshDelta } = window.av8?.auth ?? {}

const authSBLSingleton: IAuthSharedBusinessLogic = createAuthSBL(isEmbed ? EmbedAuthSbl : DesktopAuthSbl, authUrl, {
  domain,
  updateInterval,
  tokenRefreshDelta,
})

export function getAuthSBLInstance(): IAuthSharedBusinessLogic {
  return authSBLSingleton
}

export function isInstantiated(): boolean {
  return authSBLSingleton !== null
}
