import { AuthCredentials } from './Auth.interface'
import { getAuthSBLInstance } from './Auth.singleton'

const authHeaderKey = 'Authorization'
const contentTypeMethods = ['POST', 'PUT', 'PATCH']
const contentTypeKey = 'Content-Type'
const contentTypeLowerCaseKey = contentTypeKey.toLowerCase()
const contentTypeValue = 'application/json'

export type AuthedFetchRequestInit = RequestInit & { queryParams?: URLSearchParams; hideAgentId?: boolean }

export const AuthedFetch = async (url: string, init: AuthedFetchRequestInit = {}): Promise<Response> => {
  const [pathUrl, alreadyExistingQueryParamsString = ''] = url.split('?')
  const { queryParams = new URLSearchParams(), headers = new Headers(), hideAgentId = false } = init

  alreadyExistingQueryParamsString
    .split('&')
    .filter((v) => v && v.length)
    .forEach((param: string) => {
      const [key, value] = param.split('=')

      queryParams.append(key, value)
    })

  const authSBL = getAuthSBLInstance()
  const credentials: AuthCredentials = await authSBL.getCredentials()

  if (credentials) {
    if (!hideAgentId) {
      queryParams.set('agentId', credentials?.agentId ?? '')
    }

    const authHeaderValue = `Bearer ${credentials?.token ?? ''}`
    const isContentTypePossible = init.method && contentTypeMethods.includes(init.method.toUpperCase())

    if (headers instanceof Headers) {
      if (isContentTypePossible && !headers.has(contentTypeKey)) {
        headers.append(contentTypeKey, contentTypeValue)
      }

      headers.append(authHeaderKey, authHeaderValue)
    } else if (Array.isArray(headers)) {
      if (isContentTypePossible) {
        const doesNotHaveContentTypeHeader = headers.every(([key]) => key.toLowerCase() !== contentTypeLowerCaseKey)

        if (doesNotHaveContentTypeHeader) {
          headers.push(['Content-Type', 'application/json'])
        }
      }

      headers.push([authHeaderKey, authHeaderValue])
    } else {
      if (!(contentTypeKey in headers)) {
        headers[contentTypeKey] = contentTypeValue
      }

      headers[authHeaderKey] = authHeaderValue
    }
  }
  return fetch(`${pathUrl}?${queryParams}`, { ...init, headers })
}
