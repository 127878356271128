import { AgentListingDto } from '../../shared/domain/agent-listing.dto'
import { ResponseType } from '../../shared/domain/response.type'
import { PropertySearchV2Response } from './property-search-v2-response.type'
import { PropertySearchV2SearchParams } from './property-search-v2-search.params'

const PROPERTY_SEARCH_API_URL = process.env.PROPERTY_SEARCH_API_URL

export class PropertySearchV2Provider {
  async findAgentListings(agentDRE: string, authToken: string): Promise<AgentListingDto[]> {
    const results = await this.searchAddressesByDRE(agentDRE, authToken)

    if (results.success) {
      return results.data.items
    }

    return []
  }

  async findByTextSearch(text: string): Promise<AgentListingDto[]> {
    const results = await this.searchMlsAddresses({ search: text })
    if (results.success) {
      return results.data.items
    }

    return [] as AgentListingDto[]
  }

  /**
   * This method returns Skyslope + MLS addresses assigned to the agent DRE
   * @param dre Agent DRE
   * @param authToken Agent auth token
   * @returns Agent listings
   */
  private async searchAddressesByDRE(dre: string, authToken: string): Promise<ResponseType<PropertySearchV2Response>> {
    try {
      const headers = new Headers()
      headers.set('Content-Type', 'application/json')
      headers.set('Authorization', `Bearer ${authToken}`)

      const response = await fetch(`${PROPERTY_SEARCH_API_URL}/api/v2/agent-listings`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ agentDre: dre }),
      })

      if (response.ok) {
        const data = await response.json()
        return { success: true, data }
      }
      return { success: false }
    } catch (err) {
      console.error('Error fetching agent listings', err)
      return { success: false }
    }
  }

  private async searchMlsAddresses(
    params: PropertySearchV2SearchParams
  ): Promise<ResponseType<PropertySearchV2Response>> {
    try {
      const response = await fetch(`${PROPERTY_SEARCH_API_URL}/api/v2/search`, {
        method: 'POST',
        body: JSON.stringify(params),
        headers: [['Content-Type', 'application/json']],
      })

      if (response.ok) {
        const data = await response.json()
        return { success: true, data }
      }
      return { success: false }
    } catch (err) {
      return { success: false }
    }
  }
}
