export class CookieHelper {
  private map: Map<string, string>
  private readonly cookieDomain: string
  constructor(cookieDomain: string) {
    this.cookieDomain = cookieDomain
    this.update()
  }

  private get domain(): string {
    return this.cookieDomain
  }

  /**
   * Generates a map from the current Cookies available in the document
   * and stores it internally
   *
   * @returns {Map<string, string>}
   */
  update(): void {
    this.map = new Map(
      document.cookie.split(';').map((cookieText: string): Readonly<[string, string]> => {
        const [key, val] = cookieText.split('=', 2).map((str) => str.trim())
        return [key, val]
      })
    )
  }

  /**
   * Returns the value of the cookie with the given key
   * Needs to be called after update() if the cookie has been changed
   */
  getCookie(key: string): string {
    return this.map.get(key) || null
  }

  /**
   * Removes the cookies with the given keys
   * Also removes the cookies from the internal map
   */
  removeCookie(...keys: string[]): void {
    const domain = this.cookieDomain
    keys
      .filter((key) => !!key)
      .forEach((key) => {
        this.map.delete(key)
        document.cookie = `${key}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; Domain=${domain}; SameSite = Lax; Path=/`
      })
  }
}
