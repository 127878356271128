import { createContext, PropsWithChildren, useState, useEffect, useContext } from 'react'
import { useAuthContext } from '../auth'
import { AgentListingDto } from '../shared/domain/agent-listing.dto'
import { useUserDataContext } from '../user-profile/user-data.context'
import { ListingsServiceFactory } from './listings.service.factory'
import { ListingsService } from './listings.service.type'

export type ListingsContextValue = {
  listings: AgentListingDto[]
}

export const useListings: () => ListingsContextValue = () => useContext(ListingsContext)

export const ListingsContext = createContext<ListingsContextValue>({
  listings: [],
})

export const ListingsContextProvider = (props: PropsWithChildren<{ limit?: number }>): JSX.Element => {
  const { limit = 10, children } = props
  const [listings, setListings] = useState<AgentListingDto[]>([])
  const { agentId, token } = useAuthContext()
  const [listingsService, setListingsService] = useState<ListingsService>(null)
  const userData = useUserDataContext()

  useEffect(() => {
    if (agentId && token) {
      const listingService = ListingsServiceFactory(token, agentId)
      setListingsService(listingService)
    }
  }, [agentId, token])

  useEffect(() => {
    if (listingsService && userData?.dre) {
      listingsService
        .getAgentRecentAddress(userData.dre, 10)
        .then((response) => {
          if (response.success) {
            setListings(response.data.map((listing) => ({ ...listing })))
          }
        })
        .catch((error) => {
          console.error('Failed to get recent addresses', error.message, error)
        })
    }
  }, [limit, listingsService, userData])

  return <ListingsContext.Provider value={{ listings }}>{children}</ListingsContext.Provider>
}
