/**
 * These have to keep in sync with the available handlers in the mobile app
 */
export enum WebviewBridgeHandlerName {
  facebook = 'facebook',
  instagram = 'instagram',
  download = 'download',
  exit = 'exit',
  meMarketingRequest = 'meMarketingRequest',
  hideBackButton = 'hideBackButton',
  enableBackButton = 'enableBackButton',
  nonAuth = 'nonAuth',
  presentActionSheet = 'presentActionSheet',
  backHome = 'backHome',
  toggleSidebar = 'toggleSidebar',
  share = 'share',
  pdf = 'PDF',
}

export type WebviewBridgeDownloadHandler = (
  name: WebviewBridgeHandlerName.download,
  payload: { url: string[] } | { base64: string[] }
) => void

export type WebviewBridgeDownloadPDFHandler = (
  name: WebviewBridgeHandlerName.pdf,
  payload: { url: string[] } | { base64: string[] }
) => void

export type WebviewBridgeToggleSidebarHandler = (
  name: WebviewBridgeHandlerName.toggleSidebar,
  payload: { open: boolean }
) => void

export type WebviewBridgeSocialShareHandler = (
  name: WebviewBridgeHandlerName.facebook | WebviewBridgeHandlerName.instagram | WebviewBridgeHandlerName.share,
  payload: ({ url: string[] } | { base64: string[] }) & { copy?: string }
) => void

export type WebviewBridgeActionSheetHandler = (
  name: WebviewBridgeHandlerName.presentActionSheet,
  payload: { options: Array<{ label: string; value: string }>; callbackName: 'actionSheet' }
) => void

export type WebviewBridgeCommandHandler = (
  name:
    | WebviewBridgeHandlerName.exit
    | WebviewBridgeHandlerName.meMarketingRequest
    | WebviewBridgeHandlerName.hideBackButton
    | WebviewBridgeHandlerName.enableBackButton
    | WebviewBridgeHandlerName.nonAuth
    | WebviewBridgeHandlerName.backHome
) => void
