import './gtm'
import './heap'
import React, { createContext, ReactNode, useMemo } from 'react'
import { getAuthSBLInstance } from '../auth'

export type HeapObject = Record<string, string | number>

export type HeapAPI = {
  identify(userId: string): void
  addUserProperties(data: HeapObject): void
  addEventProperties(properties: HeapObject): void
  appid: string
  clearEventProperties(): void
  config: { disableTextCapture: boolean; secureCookie: boolean; trackingServer: string }
  identity: string
  loaded: boolean
  removeEventProperty(data: HeapObject): void
  resetIdentity(): void
  setEventProperties(data: HeapObject): void
  track(event: string, data?: HeapObject, r?: any): void
  unsetEventProperty(data: HeapObject): void
  userId: string
  version: Record<string, any>
}

declare global {
  interface Window {
    heap?: HeapAPI
  }
}

export const HeapContext = createContext<typeof window.heap>(undefined)

export const HeapProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  return <HeapContext.Provider value={window?.heap}>{children}</HeapContext.Provider>
}
