import kebabCase from 'lodash.kebabcase'
import upperFirst from 'lodash.upperfirst'

export type DataAttributes = {
  metaType?: string
  metaName?: string
  metaAction?: string
  metaIdx?: string
  metaId?: string
  testName?: string
  [k: string]: string | number
}

export const generateDataAttrs = (dataAttrs: DataAttributes): Record<string, string> =>
  Object.fromEntries(
    Object.entries(dataAttrs)
      .filter(([, v]) => !!v)
      .map(([attributeKey, attributeValue]) => [
        kebabCase(`data${upperFirst(attributeKey)}`),
        attributeValue.toString(),
      ])
  )
