import React, { createContext, PropsWithChildren, useState, useEffect, useContext } from 'react'
import { useAuthContext } from '../auth'
import { AgentTeammateDto } from '../shared/domain/agent-teammate.dto'
import { TeammatesServiceFactory } from './teammates.service.factory'
import { TeammatesService } from './teammates.service.type'

export type TeammatesContextValue = {
  teammates?: AgentTeammateDto[]
}

export const useTeammates: () => TeammatesContextValue = () => useContext(TeammatesContext)

export const TeammatesContext = createContext<TeammatesContextValue>({})

export const TeammatesContextProvider = (props: { children: React.ReactNode }): JSX.Element => {
  const { children } = props
  const [teammates, setTeammates] = useState<AgentTeammateDto[]>([])
  const { agentId, token } = useAuthContext()
  const [teammatesService, setTeammatesService] = useState<TeammatesService>(null)

  useEffect(() => {
    if (agentId && token) {
      const teammatesService = TeammatesServiceFactory(token, agentId)
      setTeammatesService(teammatesService)
    }
  }, [agentId, token])

  useEffect(() => {
    if (teammatesService) {
      teammatesService
        .getAgentTeammates()
        .then((response) => {
          if (!response.success) return

          setTeammates(response.data.map((teammate) => ({ ...teammate })))
        })
        .catch((error) => {
          console.error('Failed to get agent teammates', error.message, error)
        })
    }
  }, [teammatesService])

  return <TeammatesContext.Provider value={{ teammates }}>{children}</TeammatesContext.Provider>
}
