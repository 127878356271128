// Anything exported from this file is importable by other in-browser modules.

// Import and Export Types explicitly:
export type OptionDto = import('./modules/shared/domain/option.dto').OptionDto
export type WebviewBridge = import('./modules/webview-bridge/webview-bridge.type').WebviewBridge
export type AgentListingDto = import('./modules/shared/domain/agent-listing.dto').AgentListingDto
export type AgentTeammateDto = import('./modules/shared/domain/agent-teammate.dto').AgentTeammateDto
export type MLSDataPhotoType = import('./modules/shared/domain/agent-listing.dto').MLSDataPhotoType
export type ListingDetails = import('./modules/shared/domain/agent-listing.dto').ListingDetails
export type ResponseType<T> = import('./modules/shared/domain/response.type').ResponseType<T>
export type PaymentMethod = import('./modules/billing/billing-service.types').DefaultPaymentMethod
export type ListingsService = import('./modules/listings/listings.service.type').ListingsService
export type UserDataDto = import('./modules/user-profile/domain/user-data.dto').UserDataDto
export type AgentProfileDto = import('./modules/user-profile/domain/agent-profile.dto').AgentProfileDto
export type GetAgentsByFiltersDto = import('./modules/shared/domain/property-search.dto').GetAgentsByFiltersDto
export type GetAgentsByFiltersParams = import('./modules/shared/domain/property-search.dto').GetAgentsByFiltersParams
export type PropertyTypes = import('./modules/shared/domain/property-search.dto').PropertyTypes
export type PropertyStatus = import('./modules/shared/domain/property-search.dto').PropertyStatus
export type FiltersRange = import('./modules/shared/domain/property-search.dto').FiltersRange
export type Suggestion = import('./modules/shared/domain/property-search.dto').Suggestion
export type HeapAPI = import('./modules/analytics/heap.context').HeapAPI
export type HeapObject = import('./modules/analytics/heap.context').HeapObject
export type MediaType = import('./modules/design-huddle/media-type.type').MediaType
export type Template = import('./modules/design-huddle/template.type').Template

// Export functions/values below (ENV VARS SHOULD BE DECLARED IN webpack.config.js with a default value)
export const AUTH_SERVICE_URL = process.env.AUTH_SERVICE_URL
export const CONTENT_GRID_SERVICE_URL = process.env.CONTENT_GRID_SERVICE_URL
export const DH_PROXY_API_URL = process.env.DH_PROXY_API_URL
export const VISUAL_MARKETING_API_URL = process.env.VISUAL_MARKETING_API_URL
export const PROPERTY_SEARCH_API_URL = process.env.PROPERTY_SEARCH_API_URL
export const BACKEND_APP_API_URL = process.env.BACKEND_APP_API_URL

export { getAuthSBLInstance, isAuthSBLAvailable, useAuthContext, AuthedFetch, AuthProvider } from './modules/auth'
export { FeatureFlagsProvider, useFeatureFlags } from './modules/feature-flags'
export { ListingsContextProvider, useListings, ListingsServiceFactory } from './modules/listings'
export {
  PropertySearchContextProvider,
  usePropertySearch,
  PropertySearchServiceFactory,
} from './modules/property-search'
export { useUserDataContext, UserDataProvider } from './modules/user-profile/user-data.context'
export { useTeammates, TeammatesContextProvider } from './modules/teammates/teammates.context'
export { HistoryUserMenu } from './modules/user-profile/components/history-user-menu'
export {
  WebviewBridgeContext,
  WebviewBridgeProvider,
  useWebviewBridgeContext,
  // DEPRECATED - Keeping temporarily for Backward compatibility
  WebviewBridgeContext as IosBridgeContext,
  WebviewBridgeProvider as IosBridgeProvider,
} from './modules/webview-bridge/webview-bridge.context'
export {
  WebviewBridgeHandlerName,
  // DEPRECATED - Keeping temporarily for Backward compatibility
  WebviewBridgeHandlerName as IosBridgeHandlerName,
} from './modules/webview-bridge/webview-bridge-handlers.type'
export { useBilling, BillingServiceProvider } from './modules/billing'
export { PubSubBus } from './modules/pubsub/index'
export { HeapContext, HeapProvider } from './modules/analytics/heap.context'
export { generateDataAttrs, getOS, triggerDownload, UrlDownload, ZipDownload } from './modules/shared/utils'
export { ExportShareModal } from './modules/shared/components/ExportShareModal'
export { ErrorBoundary } from './modules/error-handlers/ErrorBoundary'
export { initDatadogRum, datadogRum } from './modules/datadog/datadog-rum'
export { initDatadogLogs, datadogLogs } from './modules/datadog/datadog-logs'
