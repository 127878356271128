import { getAuthSBLInstance } from '../auth'
import { AuthCredentials } from '../auth/Auth.interface'
import { BillingServiceInterface } from './billing-service.interface'
import { Response as BillingResponse, DefaultPaymentMethod } from './billing-service.types'
import { ResponseType } from '../shared/domain/response.type'

interface GraphQLQuery {
  readonly query: string
  variables?: any
}

const URL = process.env.BILLING_API_URL

export function BillingServiceFactory(): BillingServiceInterface {
  const sendGraphQLRequest = async (body: GraphQLQuery): Promise<Response> => {
    const authSBL = getAuthSBLInstance()
    const credentials: AuthCredentials = await authSBL.getCredentials()
    return fetch(URL, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${credentials.token}`,
        'Content-Type': 'application/json',
        'x-custom-header': JSON.stringify({ agentId: credentials.agentId }),
      },
    })
  }

  return {
    getDefaultPaymentMethod: async (): Promise<ResponseType<DefaultPaymentMethod>> => {
      try {
        const query: GraphQLQuery = {
          query: ` query {
          defaultPaymentMethod {
            id
            object
            card {
              exp_month
              exp_year
              last4
              brand
              funding
            }
            billing_details {
              name
            }
          }
        }`,
        }
        const response = await sendGraphQLRequest(query)
        if (response.ok) {
          const { data } = await response.json()
          return { success: true, data }
        }
        console.error('[BillingService] - Error fetching default payment method')
        return { success: false }
      } catch (error) {
        console.error(error.message)
        return { success: false }
      }
    },
  }
}

export const BillingService = BillingServiceFactory()
