import { CookieHelper } from '../shared/utils/CookieHelper'
import {
  AuthCredentials,
  AuthSharedBusinessLogicConstructorOptions,
  IAuthSharedBusinessLogic,
  OnAuthChangeHandler,
} from './Auth.interface'

const COOKIE_TOKEN = 'token'
const COOKIE_TOKEN_EXPIRATION = 'token_expiration'
const COOKIE_REFRESH_TOKEN = 'refresh'

export class DesktopAuthSbl implements IAuthSharedBusinessLogic {
  private agentId: string
  private authUrl: string
  private cookieHelper: CookieHelper
  private credentials: AuthCredentials
  private handlers: Set<OnAuthChangeHandler>
  private refreshPromise: Promise<any>
  private refreshToken: string
  private token: string
  private tokenExpiration: Date
  private tokenIssuer: string
  private tokenRefreshDelta: number
  private updateInterval: number

  constructor(options: AuthSharedBusinessLogicConstructorOptions) {
    const { authUrl, domain = 'avenue8.com', updateInterval = 5000, tokenRefreshDelta = 60000 } = options
    this.updateInterval = Math.abs(updateInterval)
    this.tokenRefreshDelta = Math.abs(tokenRefreshDelta)
    this.authUrl = authUrl
    this.cookieHelper = new CookieHelper(domain)
    this.handlers = new Set<OnAuthChangeHandler>([])
    this.credentials = null

    this.copyDataFromCookiesAndToken()
    this.updateCredentials()
    this.updateAuthState().then(() => this.scheduleUpdate())
  }

  private scheduleUpdate() {
    setTimeout(() => {
      this.updateAuthState().then(() => this.scheduleUpdate())
    }, this.updateInterval)
  }

  /**
   * Copies the data from some cookies and the token to the internal state.
   */
  private copyDataFromCookiesAndToken() {
    const cookies = this.cookieHelper
    cookies.update()

    this.token = cookies.getCookie(COOKIE_TOKEN)
    this.refreshToken = cookies.getCookie(COOKIE_REFRESH_TOKEN)

    const tokenData = this.extractJwtData(this.token)
    if (tokenData) {
      this.agentId = tokenData.subject
      this.tokenIssuer = tokenData.issuer
      this.tokenExpiration = new Date(tokenData.expiration * 1000)
    } else {
      this.agentId = null
      this.tokenIssuer = null
      this.tokenExpiration = null
    }
  }

  private extractJwtData(token: string): { expiration: number; issuer: string; email: string; subject: string } {
    if (!token) return null
    const payload = token.split('.')[1]
    const decodedPayload = atob(payload)
    const { iss: issuer, email, sub: subject, exp: expiration } = JSON.parse(decodedPayload)
    return { expiration, issuer, email, subject }
  }

  /**
   * Consolidate a credentials object from the internal state and notify all handlers
   */
  private updateCredentials() {
    const previousCredentials = { ...this.credentials }

    const { agentId, token, refreshToken: refresh, tokenExpiration: expiresAt } = this
    this.credentials = token && refresh ? { agentId, token, refresh, expiresAt } : null

    // compare credentials properties to see if they have changed
    const credentialsKeys: Array<keyof AuthCredentials> = ['agentId', 'token', 'refresh', 'expiresAt']
    const hasCredentialsChanged = credentialsKeys.some((key) => {
      return previousCredentials[key]?.toString() !== this.credentials?.[key]?.toString()
    })

    // if credentials have not changed, do not notify handlers to avoid unnecessary re-renders
    if (!hasCredentialsChanged) return

    Array.from(this.handlers.values()).forEach((handler: OnAuthChangeHandler) => {
      handler(this.credentials).catch((err) => {
        console.error('[Auth SBL] Failed to run handler:', err)
      })
    })
  }

  private async invokeRefresh() {
    const params = new URLSearchParams({ refreshToken: this.refreshToken })
    const refreshUrl = `${this.authUrl}/auth/google/refresh?${params}`
    this.refreshPromise = fetch(refreshUrl, { credentials: 'include' })
      .then((response) => {
        if (!response.ok) {
          this.cookieHelper.removeCookie(COOKIE_REFRESH_TOKEN)
          return this.requestAuthentication()
        }
      })
      .catch((error) => {
        console.error('[Auth SBL] Failed to refresh', error.message, error)
        this.cookieHelper.removeCookie(COOKIE_REFRESH_TOKEN)
      })
      .then(() => {
        this.copyDataFromCookiesAndToken()
      })
    return this.refreshPromise
  }

  /**
   * Updates the auth state.
   * Called periodically.
   * Checks if the token is expired and refreshes it if needed.
   */
  private async updateAuthState() {
    this.copyDataFromCookiesAndToken()

    if (this.token) {
      if (!this.hasValidTokenIssuer()) {
        this.signOut()
        return
      }

      if (this.tokenIsExpired()) {
        this.cookieHelper.removeCookie(COOKIE_TOKEN, COOKIE_TOKEN_EXPIRATION)
        this.copyDataFromCookiesAndToken()
        this.updateCredentials()
        return
      }
    } else {
      if (this.refreshToken) {
        await this.invokeRefresh()
        this.updateCredentials()
        return
      }
    }
  }

  private tokenIsExpired() {
    const currentTime = new Date().getTime()
    const tokenExpirationTime = new Date(this.tokenExpiration).getTime()
    const tokenLifespan = tokenExpirationTime - currentTime
    const cookieIsExpired = tokenLifespan - this.tokenRefreshDelta <= 0
    return cookieIsExpired
  }

  /**
   * Checks if the token issuer matches the authentication server we are using.
   */
  private hasValidTokenIssuer() {
    // if (!this.tokenIssuer) return true
    return this.authUrl.startsWith(this.tokenIssuer)
  }

  public addOnAuthChangeListener(handler: OnAuthChangeHandler): void {
    this.handlers.add(handler)
  }

  public removeOnAuthChangeListener(handler: OnAuthChangeHandler): void {
    this.handlers.delete(handler)
  }

  public async isAuthenticated(): Promise<boolean> {
    return this.credentials !== null
  }

  public async getCredentials(): Promise<AuthCredentials> {
    if (this.refreshPromise) {
      await this.refreshPromise
    }
    return this.credentials
  }

  public async requestAuthentication(redirect = true): Promise<void> {
    if (redirect) {
      const params = new URLSearchParams({ redirect: window.location.href })
      window.location.href = `${this.authUrl}/auth/google?${params}`
    } else {
      console.error('Not supported yet')
    }
  }

  public async signOut(redirectTo?: string): Promise<void> {
    this.credentials = null
    this.cookieHelper.removeCookie(COOKIE_TOKEN, COOKIE_TOKEN_EXPIRATION, COOKIE_REFRESH_TOKEN)
    this.copyDataFromCookiesAndToken()
    this.updateCredentials()
    if (redirectTo) {
      window.location.href = redirectTo
    } else {
      window.location.href = window.location.origin
    }
  }
}
