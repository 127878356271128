interface IPubSub {
  publish: (topic: string, argument?: any) => void
  subscribe: (topic: string, handler: (argument?: any) => void) => void
  unsubscribe: (topic: string, handler: (argument?: any) => void) => void
}
class PubSub implements IPubSub {
  private handlers

  constructor() {
    this.handlers = {}
    this.addListener()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.publish = this.publish
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.unsubscribe = this.unsubscribe
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.publish = this.publish
  }

  public publish = (topic: string, argument) => {
    const ev = new CustomEvent<Record<string, any>>('pubsub', {
      detail: { topic, argument },
    })
    document.body.dispatchEvent(ev)
  }

  public subscribe = (topic: string, handler: (argument?: any) => void) => {
    const topicHandlers = this.handlers[topic] || []
    topicHandlers.push(handler)
    this.handlers[topic] = topicHandlers
  }

  public unsubscribe = (topic: string, handler: (argument?: any) => void) => {
    const topicHandlers = this.handlers[topic] || []
    const index = topicHandlers.indexOf(handler)
    index >= 0 && topicHandlers.splice(index, 1)
  }

  private pubsubListener = (ev: CustomEvent<Record<string, string>>) => {
    const { topic, argument } = ev.detail
    const topicHandlers = this.handlers[topic] || []
    topicHandlers.forEach((handler) => handler(argument))
  }

  private addListener = () => {
    document.body.addEventListener('pubsub', (ev: CustomEvent) => this.pubsubListener(ev))
  }
}

const PubSubBus = new PubSub()

export { PubSubBus }
