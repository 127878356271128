import { datadogRum as datadogRumInstance, RumInitConfiguration } from '@datadog/browser-rum'
import { getAuthSBLInstance } from '../auth'
import { AuthCredentials } from '../auth/Auth.interface'

type DatadogRumInstance = typeof datadogRum

export const datadogRum = datadogRumInstance

const handler =
  (options: Partial<RumInitConfiguration> = {}) =>
  async (credentials: AuthCredentials) => {
    const initConfig = datadogRum.getInitConfiguration()

    if (!initConfig) {
      datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        site: process.env.REACT_APP_DATADOG_SITE ?? 'us5.datadoghq.com',
        service: process.env.REACT_APP_SERVICE_NAME,
        version: process.env.REACT_APP_SERVICE_VERSION,
        env: process.env.NODE_ENV,
        sampleRate: 100,
        trackResources: true,
        trackLongTasks: true,
        trackInteractions: true,
        ...options,
      })
    }

    const jwt = credentials ? credentials.token : null

    if (!jwt) return

    try {
      const payload = JSON.parse(atob(jwt.split('.')[1]))
      const { sub: id, username: email } = payload
      datadogRum.setUser({ email, id })
    } catch (err) {
      console.error('Could not read the username in JWT')
      datadogRum.removeUser()
    }
  }

export function initDatadogRum(options?: Partial<RumInitConfiguration>): DatadogRumInstance {
  if (!process.env.REACT_APP_DATADOG_APPLICATION_ID || !process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
    console.warn('Datadog RUM is not configured.')
    return
  }
  getAuthSBLInstance().addOnAuthChangeListener(handler(options))
  getAuthSBLInstance().getCredentials().then(handler(options))
  return datadogRum
}
