import { ResponseType } from '../shared/domain/response.type'
import { TeammatesService } from './teammates.service.type'
import { AgentTeammateDto } from '../shared/domain/agent-teammate.dto'
import { AuthedFetch } from '../auth'

const VISUAL_MARKETING_API_URL = process.env.VISUAL_MARKETING_API_URL

export class TeammatesServicesImpl implements TeammatesService {
  agentId: string
  token: string

  constructor(agentId: string, token: string) {
    this.agentId = agentId
    this.token = token
  }

  async getAgentTeammates(): Promise<ResponseType<AgentTeammateDto[]>> {
    try {
      const res = await AuthedFetch(`${VISUAL_MARKETING_API_URL}/teammates`)
      const data = await res.json()

      return {
        success: true,
        data,
      }
    } catch (err) {
      return { success: false }
    }
  }
}
