import { createContext, PropsWithChildren, useContext } from 'react'
import { BillingService } from './billing.service'
import { BillingServiceInterface } from './billing-service.interface'

export const BillingServiceContext = createContext<BillingServiceInterface>({
  getDefaultPaymentMethod: async () => {
    throw new Error('not implemented yet')
  },
})

export const useBilling = (): BillingServiceInterface => useContext(BillingServiceContext)

export const BillingServiceProvider = ({
  children,
  value = BillingService,
}: PropsWithChildren<{ value?: BillingServiceInterface }>): JSX.Element => {
  return <BillingServiceContext.Provider value={value}>{children}</BillingServiceContext.Provider>
}
