type CommonOperatingSystems = 'isWindows' | 'isMacOS' | 'isLinux' | 'isAndroid' | 'isIOS'

export default function getOS(): Record<CommonOperatingSystems, boolean> {
  const { userAgent } = window.navigator
  return {
    isWindows: userAgent.includes('Windows'),
    isMacOS: userAgent.includes('Mac OS') && !userAgent.includes('iPhone') && !userAgent.includes('iPad'),
    isLinux: userAgent.includes('Linux'),
    isAndroid: userAgent.includes('Android'),
    isIOS: userAgent.includes('iPhone') || userAgent.includes('iPad') || userAgent.includes('Mac OS'),
  }
}
