import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { AuthCredentials, IAuthSharedBusinessLogic } from './Auth.interface'
import { getAuthSBLInstance } from './Auth.singleton'

export interface IAuthContext {
  authSBL?: IAuthSharedBusinessLogic
  token?: string
  agentId?: string
}

export const AuthContext = createContext<IAuthContext>({})

export const useAuthContext = (): IAuthContext => useContext(AuthContext)

export const AuthProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [token, setToken] = useState<string | undefined>()
  const [agentId, setAgentId] = useState<string | undefined>()

  useEffect(() => {
    const authSBL = getAuthSBLInstance()
    const handler = async (credentials: AuthCredentials) => {
      setToken(credentials?.token ?? undefined)
      setAgentId(credentials?.agentId ?? undefined)
    }
    authSBL.addOnAuthChangeListener(handler)
    authSBL.getCredentials().then(handler)
    return () => {
      authSBL.removeOnAuthChangeListener(handler)
    }
  }, [])

  return (
    <AuthContext.Provider value={{ authSBL: getAuthSBLInstance(), token, agentId }}>{children}</AuthContext.Provider>
  )
}
